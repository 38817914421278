<template>
  <div class="card card-custom">
    <appointmentDetailModal
      v-on:closemodal="closeModal"
      :loading-data="loadingData"
      :has-data="hasData"
      :show-modal="appointmentDetailModalShow"
      :appointment-data="appointmentData"
      :index-num="currentIndex"
      :show-qr-code="false"
      :token="token"
      :payment-log="true"
    ></appointmentDetailModal>

    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by appointment no or name"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="Search()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="Search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-5"></div>
        <div class="col-md-3">
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              v-model="searchQuery"
              placeholder="Search"
            />
          </div>
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input type="date" v-model="date_from" class="form-control" />
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input type="date" v-model="date_to" class="form-control" />
          </div>
        </div>
        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <select v-model="status" class="form-control">
              <option value="all">All Payment Status</option>
              <option value="Approval">Approved</option>
              <option value="Declined">Declined</option>
            </select>
          </div>
        </div>

        <div class="col-md-1">
          <button class="btn btn-outline-info" @click="Search()">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <div class="row" v-if="!getLoadingData">
        <div class="col-12">
          <v-table :columns="columns">
            <tr v-for="(item, index) in resultQuery" :key="index">
              <td>{{ ++index }}</td>
              <td>
                {{ item.card_reference }}
              </td>
              <td class="">
                <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.appointment, item.appt_token)
                  "
                >
                  {{ item.appointment }}</a
                >
              </td>
              <td>{{ datetimeFormatAmPm(item.created_at) }}</td>
              <td>{{ item.log_data.name }}</td>
              <td>{{ item.applicant_name }}</td>
              <td class="text-right">
                $ {{ item.log_data.amt | toFixedTwoDigit }}
              </td>
              <td class="text-center">{{ item.log_data.location }}</td>
              <td>{{ item.log_data.resptext }}</td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="payment_log.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no log(s) found</h4>
        </div>
      </div>
      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
      <hr v-if="pagination.total_page > 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page > 1"
        :pagination="pagination"
      ></vl-pagination>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_PAYMENT_LOG,
  FETCH_SELF_APPOINMENT
} from "@/core/services/store/actions.type";
import vlPagination from "@/includes/table/Pagination";
import vDataLoader from "@/components/frontend/spinner.vue";
import appointmentDetailModal from "@/components/frontend/modal/appointmentdetail";

import vTable from "@/includes/table/Datatable";
export default {
  components: {
    vTable,
    vDataLoader,
    vlPagination,
    appointmentDetailModal
  },
  data() {
    return {
      searchQuery: null,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      },
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Logs",
          route: ""
        },
        {
          id: 2,
          title: "Payments",
          route: "log.payment"
        }
      ],
      status: "all",
      search_text: "",
      date_from: this.$moment(new Date()).format("YYYY-MM-DD"),
      date_to: this.$moment(new Date()).format("YYYY-MM-DD"),
      getLoadingData: false,
      payment_log: [],
      columns: [
        {
          name: "sn",
          width: "",
          label: "S.N",
          class: ""
        },
        {
          name: "transaction",
          width: "",
          label: "Transaction #",
          class: ""
        },
        {
          name: "appointmentid",
          width: "",
          label: "App No.",
          class: ""
        },
        {
          name: "date",
          width: "",
          label: "Date",
          class: ""
        },

        {
          name: "name",
          width: "",
          label: "Name Of card",
          class: ""
        },
        {
          name: "Applicant Name",
          width: "",
          label: "Applicant Name",
          class: ""
        },
        {
          name: "amt",
          width: "",
          label: "Amount",
          class: "text-right"
        },
        {
          name: "location",
          width: "",
          label: "Location",
          class: "text-center"
        },
        {
          name: "status",
          width: "",
          label: "Status",
          class: ""
        }
      ],
      appointmentDetailModalShow: false,
      loadingData: false,
      hasData: false,
      appointmentData: {},
      currentIndex: -1,
      token: ""
    };
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.payment_log.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.card_reference.toLowerCase().includes(v) ||
                item.log_data.resptext.toLowerCase().includes(v) ||
                // item.log_data.post_request.toLowerCase().includes(v) ||
                // item.log_data.return_response.toLowerCase().includes(v) ||
                item.log_data.location.toLowerCase().includes(v) ||
                item.log_data.name.toLowerCase().includes(v) ||
                item.applicant_name.toLowerCase().includes(v) ||
                item.appointment.toString().includes(v)
            );
        });
      } else {
        return this.payment_log;
      }
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    // this.Search();
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  methods: {
    closeModal() {
      this.appointmentDetailModalShow = false;
    },
    openAppointmentDetail(appId, token) {
      this.appointmentDetailModalShow = true;
      this.selectedAppId = appId;
      this.loadingData = true;
      var data = {
        token: token,
        appointment_id: appId
      };
      this.$store
        .dispatch(FETCH_SELF_APPOINMENT, data)
        .then(res => {
          this.appointmentData = res.data;
          this.token = token;

          this.loadingData = false;
          this.hasData = res.hasData;
          this.appointmentData = res.data;
        })
        .catch(() => {
          alert();
          this.loadingData = false;
        });
    },
    Search() {
      this.getLoadingData = true;

      var data = {
        date_from: this.date_from,
        date_to: this.date_to,
        page: this.pagination.current_page,
        status: this.status,
        search_text: this.search_text
      };
      this.$store
        .dispatch(FETCH_PAYMENT_LOG, data)
        .then(res => {
          this.getLoadingData = false;
          this.payment_log = res.data;
          this.pagination.total_records = res.total_record;
          this.pagination.total_page = res.page_range;
          this.pagination.received_per_page_data = res.data.length;
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.Search();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.Search();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.Search();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.Search();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.Search();
    }
  }
};
</script>
